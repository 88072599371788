<div class="d-flex h-100" style="width: -webkit-fill-available">
  <div
    class="product-box d-flex flex-column justify-content-between"
    [id]="product.productID"
    [class]="class"
    style="width: -webkit-fill-available"
  >
    <div>
      <div class="d-flex align-items-center justify-content-end gap-1">
        <div *ngIf="isAuthenticated">
          <a
            title="Thêm vào sản phẩm yêu thích"
            href="javascript:void(0)"
            class="wishlist-button {{
              !inWishList ? '' : 'active'
            }} d-flex align-items-center justify-content-center"
            (click)="addToWishlist(product.productID)"
          >
            <i
              class="wishlist-icon {{
                !inWishList ? 'ri-heart-line' : 'ri-heart-fill fill'
              }} ri-lg"
              style="margin-top: 2px"
            ></i>
          </a>
        </div>
      </div>
      <div class="product-image">
        <a [routerLink]="['/product/', product.slug]">
          <img
            [src]="product.imageUrls[0]"
            class="img-fluid d-flex"
            [alt]="product.name"
          />
        </a>
      </div>
    </div>
    <div *ngIf="labels?.length else noLabels" class="d-flex flex-wrap gap-1 pb-2">
      <div
        class="product-label {{ i % 2 == 0 ? 'best-seller' : 'gift' }}"
        *ngFor="let l of labels; let i = index"
      >
        <div type="button" (click)="queryStringBuilder(l.slug)">
          <h6>{{ l.name }}</h6>
        </div>
      </div>
    </div>
    <ng-template #noLabels>
      <div class="d-flex flex-wrap gap-1 pb-2"></div>
    </ng-template>
    <div class="product-detail">
      <a [routerLink]="['/product/', product.slug]">
        <h6 class="name">{{ product.name }}</h6>
      </a>
      <div
        *ngIf=" product.stockStatus != 8 && product.basePrice > 0 && isAuthenticated"
      >
        <p *ngIf="!product.pricingTablePrice">{{ product.basePrice }}</p>
        <!-- <h6 class="unit mt-1" *ngIf="product.unit">{{ product.unit }}</h6> -->
        <div class="sold text-content d-flex" *ngIf="isAuthenticated">
          <h4
            style="font-weight: 600;"
            [ngClass]="
              product.pricingTablePrice &&
              product.pricingTablePrice < product.basePrice &&
              !product.flashSalePrice
                ? 'text-danger'
                : 'theme-color'
            "
            >{{
              product.flashSalePrice ??
                (product.pricingTablePrice | currencySymbol)
            }}
          </h4>
          <del
            *ngIf="
              (product.pricingTablePrice &&
                product.pricingTablePrice < product.basePrice) ||
              product.flashSalePrice
            "
            >{{ product.basePrice | currencySymbol }}</del
          >
          <span
            *ngIf="
              product.pricingTablePrice < product.basePrice &&
                !product.flashSalePrice;
              else noPromo
            "
            class="ms-2 fw-bold text-danger bg-warning px-1 rounded-4 promo-label"
          >
            {{
              -(product.basePrice - product.pricingTablePrice) /
                product.basePrice | percent
            }}
          </span>
          <ng-template #noPromo>
            <span class="d-none d-lg-block d-xl-block">&nbsp;</span>
          </ng-template>
        </div>
      </div>
      <div *ngIf="product.wholesalePrices?.length">
        <h6 class="py-2 text-danger" style="font-weight: 500;">Mua sỉ từ {{ product.wholesalePrices[0].minQuantity }}: {{product.wholesalePrices[0].wholesalePrice | currencySymbol}}</h6>
      </div>
      <div *ngIf="product.productOrderLimit && product.productOrderLimit.maxOrderQuantityPerOrder > 0">
        <h6 class="text-danger" style="font-weight: 500;">Tối đa {{ product.productOrderLimit.maxOrderQuantityPerOrder }} sản phẩm trên đơn</h6>
      </div>
      <div *ngIf="product.productOrderLimit && product.productOrderLimit.maxOrderQuantityPerDay > 0">
        <h6 class="text-danger" style="font-weight: 500;">Tối đa {{ product.productOrderLimit.maxOrderQuantityPerDay }} sản phẩm trong ngày</h6>
      </div>
      <ng-template #noWholesalePrice>
        <span class="py-2 text-danger">&nbsp;</span>
      </ng-template>
      <div
        *ngIf="isAuthenticated"
        class="py-2"
      >
        <div *ngIf="product.basePrice <= 0">
          <a routerLink="/account/my-business-profile"><h5 *ngIf="product.stockStatus == 8" class="text-danger">Cần hoàn thiện hồ sơ để xem giá</h5></a>
          <h5 *ngIf="product.stockStatus < 8" class="text-danger">Sản phẩm tạm thời hết hàng</h5>
        </div>
        <div *ngIf="product.basePrice > 0">
          <a routerLink="/account/my-business-profile"><h5 *ngIf="product.stockStatus == 9" class="text-danger">{{ "Cần hoàn thiện hồ sơ để đặt hàng" | translate }}</h5></a>
        </div>
      </div>
      
      <!-- <div class="product-rating">
        <ngb-rating [rate]="product.rating_count"></ngb-rating>
        <h6 class="theme-color" *ngIf="product.stockStatus == 'in_stock'"></h6>
      </div> -->
      <div
        class="add-to-cart-box"
        *ngIf="product.basePrice > 0"
      >
        <div class="cart_qty qty-box open position-relative" *ngIf="product.stockStatus != 9">
          <div class="input-group" *ngIf="isAuthenticated">
            <app-counter
              [cartItem]="cartItem"
              style="width: 100%"
            ></app-counter>
          </div>
        </div>
      </div>
      <div
        class="add-to-cart-box"
        *ngIf="isAuthenticated && product.basePrice <= 0"
      ></div>
    </div>
  </div>
</div>
<app-product-detail-modal
  #productDetailModal
  [product]="product"
></app-product-detail-modal>
<app-variation-modal #variationModal></app-variation-modal>
